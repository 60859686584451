import { useEffect } from "react";
import axios from "axios";
import { getPrepaidReloadPaymentUrl } from "../../../redux/URL";

const showErrorAlert = (message) => {
  // Create the overlay to blur the background
  const overlay = document.createElement("div");
  overlay.style.position = "fixed";
  overlay.style.top = "0";
  overlay.style.left = "0";
  overlay.style.width = "100vw";
  overlay.style.height = "100vh";
  overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)"; // Semi-transparent overlay
  overlay.style.backdropFilter = "blur(5px)"; // Blur effect for the background
  overlay.style.zIndex = 999; // Behind the alert but above other content

  // Create the alert box
  const alertBox = document.createElement("div");
  alertBox.style.backgroundColor = "#193670"; // Dark blue background
  alertBox.style.color = "#FFFFFF"; // White text for the title
  alertBox.style.padding = "20px";
  alertBox.style.borderRadius = "10px";
  alertBox.style.position = "fixed";
  alertBox.style.top = "50%"; // Center vertically
  alertBox.style.left = "50%"; // Center horizontally
  alertBox.style.transform = "translate(-50%, -50%)"; // Offset the position to truly center it
  alertBox.style.zIndex = 1000; // Above the overlay
  alertBox.style.maxWidth = "400px";
  alertBox.style.minWidth = "300px";
  alertBox.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
  alertBox.style.display = "flex";
  alertBox.style.flexDirection = "column";
  alertBox.style.alignItems = "center";

  // Title
  const title = document.createElement("div");
  title.style.fontWeight = "bold";
  title.style.fontSize = "18px";
  title.innerText = "Error";

  // Error message
  const messageText = document.createElement("div");
  messageText.style.color = "#D1D5DB"; // Light gray text for the message
  messageText.style.marginTop = "10px";
  messageText.style.marginBottom = "20px";
  messageText.style.fontSize = "14px";
  messageText.innerText = message;

  // Icon (red circle with exclamation mark)
  const imgContainer = document.createElement("div");
  imgContainer.style.display = "flex";
  imgContainer.style.alignItems = "center";
  imgContainer.style.justifyContent = "center";
  imgContainer.style.gap = "10px";

  const icon = document.createElement("div");
  icon.style.width = "40px";
  icon.style.height = "40px";
  icon.style.backgroundColor = "#FF4D4F"; // Red circle background
  icon.style.borderRadius = "50%";
  icon.style.display = "flex";
  icon.style.justifyContent = "center";
  icon.style.alignItems = "center";
  icon.style.color = "white";
  icon.style.fontSize = "20px";
  icon.innerText = "!"; // Exclamation mark inside

  imgContainer.appendChild(icon);
  imgContainer.appendChild(messageText);

  // OK Button
  const button = document.createElement("button");
  button.innerText = "Ok";
  button.style.backgroundColor = "#007bff"; // Light blue button
  button.style.color = "white";
  button.style.border = "none";
  button.style.borderRadius = "5px";
  button.style.padding = "8px 16px";
  button.style.cursor = "pointer";
  button.style.marginTop = "10px";
  button.onclick = () => {
    document.body.removeChild(alertBox);
    document.body.removeChild(overlay);
  };

  alertBox.appendChild(title);
  alertBox.appendChild(imgContainer);
  alertBox.appendChild(button);

  document.body.appendChild(overlay);
  document.body.appendChild(alertBox);
};

const PayHere = ({ setLoading, onSuccess }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.payhere.lk/lib/payhere.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const setupPayHereEventHandlers = async () => {
      if (window.payhere) {
        window.payhere.onCompleted = async function (orderId) {
          console.log(orderId, "order is completed");

          // Payment Verify

          let body = new URLSearchParams({
            ReferenceNo: orderId,
            APIKey: "LTE_WEB",
          });
          try {
            const resultPayment = await axios.post(
              getPrepaidReloadPaymentUrl,
              body,
              {
                headers: {
                  "X-IBM-Client-Id": "34cfc6db495c327709aa0ad47cd3fbd6",
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            );

            if (resultPayment.data.isSuccess) {
              if (resultPayment.data.ReloadStatus === "1001") {
                window.location.reload();
              } else {
                showErrorAlert(
                  "Reload Unsuccessful, amount will be refunded to the relevant payment channel."
                );
              }
            } else {
              showErrorAlert("Reload Unsuccessful, amount will be refunded to the relevant payment channel.");
            }
          } catch (error) {
            console.error("Error during payment verification:", error);
          }

          setLoading(false);
          onSuccess();
        };

        window.payhere.onDismissed = function () {
          //console.log("order is not completed onDismissed");
          setLoading(false);
        };

        window.payhere.onError = function (error) {
         // showErrorAlert("Reload Unsuccessful, payment failed.");
          //console.log("order is not completed onError");
          setLoading(false);
        };
      } else {
        setTimeout(setupPayHereEventHandlers, 500);
      }
    };

    setupPayHereEventHandlers();
  }, []);

  const initiatePayment = (payment) => {
    if (window.payhere) {
      window.payhere.startPayment(payment);
    }
  };

  return { initiatePayment };
};

export default PayHere;
