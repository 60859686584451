import React, {useState} from 'react';
import {purchaseProtocolReportUrl} from "../../../redux/URL";
import axiosInstance from "../../../redux/axiosInstance";
import LoadingBbBody from "../LoadingBbBody";
import {connect} from "react-redux";
import {getProfile} from "../../../redux/action/broadBand.action";
import SuccessAlert from "../../alerts/SuccessAlert";
import ErrorAlert from "../../alerts/ErrorAlert";
import {Redirect} from 'react-router-dom';
import TermsConditons from "../../common/TermsConditons";
import {CATCH_ERROR_MSG} from "../../../redux/Types";

function ConfirmSubscription({type, period, setState, profile, getProfile}) {
    const [agree, setAgree] = useState(false);
    const [showTerms, setShowTerms] = useState(false);

    const [loading, setLoading] = useState(false);

    const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    const postPaid = () => {
        if (agree) {
            setLoading(true)
            let url = purchaseProtocolReportUrl + 'subscriberID=' + profile.subscriberid + '&reporterPackage=' + period + '&activatedBy=' + profile.subscriberid
            axiosInstance.post(url).then(response => {
                if (response.status === 200 && response.data.isSuccess) {
                    setSuccess(true)
                    setLoading(false)
                    getProfile(profile.subscriberid)
                } else {
                    setError(response.data.errorShow)
                    setIsError(true)
                    setLoading(false)
                }

            }).catch(Onerror => {
                setError(CATCH_ERROR_MSG)
                setIsError(true)
                setLoading(false)
            })
        } else {
            setError('Please agree')
            setIsError(true)
        }

    }

    const onSubmit = () => {
        return <Redirect to='https://billpay.slt.lk/bbtopup/summaryallAPI.php'/>
    }

    if (loading) {
        return (
            <div className='subscribeDetailReport common-panel'>
                <div className='body-container'>
                    <div className='title'>Confirm Payment</div>
                    <LoadingBbBody loading={loading} border={false}/>
                </div>
            </div>
        )
    } else if (showTerms)
        return <div className='paymentBody common-panel'>
            <TermsConditons setShowTerms={setShowTerms} setAgree={setAgree}/>
        </div>
    else {
        return (
            <div className='subscribeDetailReport common-panel'>

                {success ?
                    <SuccessAlert setSuccess={setSuccess} msg='Successfully subscribe for daily usage report'/> : null}
                {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError}/> : null}

                <div className='body-container'>
                    <div className='title'>Confirm Payment</div>
                    <div className='wrapper'>

                        <div className='confirmPayment'>
                            <div className='topDetails'>Subscribe to usage report
                                : {period === 1 ? 'Monthly' : 'Yearly'}</div>
                            <div className='price'>Rs.{period === 1 ? '40' : '400'} + Tax</div>
                            <div className='agreeCondition'>
                                <input type='checkbox' className='sign' checked={agree}
                                       onChange={(e) => setAgree(e.target.checked)}/>
                                <label className=''>I agree to the general <strong
                                    onClick={() => setShowTerms(true)}> terms and conditions</strong> </label>
                            </div>

                            <div className='actionButton'>
                                <button onClick={() => setState('period')}>Back</button>
                                {
                                    type === 'addToBill' ?
                                        <button disabled={!agree} onClick={() => postPaid()}>Submit</button> :
                                        <form action='https://billpay.slt.lk/bbtopup/summaryallAPImyslt.php'
                                              method='POST' onSubmit={onSubmit}>
                                            <input hidden={true} name='ContactNumber' value={profile.phone}/>
                                            <input hidden={true} name='CustEmail' value={profile.email}/>
                                            <input hidden={true} name='subscriberID' value={profile.subscriberid}/>
                                            <input hidden={true} name='prepaidID' value={'PAR'}/>
                                            <input hidden={true} name='reciever' value={''}/>
                                            <input hidden={true} name='packageId' value={''}/>
                                            <input hidden={true} name='channel' value={'SLTPRE'}/>
                                            <input hidden={true} name='commitUser' value={'Omni'}/>
                                            <input hidden={true} name='reporterPackage' value={period}/>
                                            <input hidden={true} name='activatedBy'
                                                   value={localStorage.getItem('userName')}/>
                                            <button type='submit' disabled={!agree}>Submit</button>
                                        </form>
                                    // <button>Pay Now</button>
                                }

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    loadingProfile: state.broadBand.loadingProfile,
    profile: state.broadBand.profile,
})

export default connect(mapStateToProps, {getProfile})(ConfirmSubscription);
