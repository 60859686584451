import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { numberWithCommas } from "../../redux/action/bill/billHistory.action";
import { isPrepaid } from "../../redux/action/accountDetails.action";
import { formatDateTimeByString } from "../../utils/utils";
import PaymentsErrorAlert from "../alerts/PaymentsErrorAlert";

function Payments({
  loading,
  payments,
  error,
  accountList,
  serviceList,
  loadingSubscribeStatus,
  subscribeStatus,
  errorSubscribeStatus,
}) {
  const history = useHistory();

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isPrepaidAcc = isPrepaid(serviceList && serviceList);

  const handleReloadClick = () => {
    if (subscribeStatus?.statusName === "Pool") {
      setErrorMessage(
        "Your Prepaid Connection has been dormant for too long, please contact 1212 to reconnect."
      );
      setIsError(true);
    } else {
      history.push("/prepaid/reload");
    }
  };

  const paymentSecure = () => {
    if (loading) {
      return "Loading...";
    } else if (error) {
      return "Failed Loading";
    } else if (!payments) {
      return "N/A";
    } else if (payments.listofbillingInquiryType === undefined) {
      return "N/A";
    } else if (payments.listofbillingInquiryType.length > 0) {
      return (
        "Rs " +
        numberWithCommas(
          payments.listofbillingInquiryType[0].outstandingBalance
        )
      );
    } else return "N/A";
  };

  const paymentSecurePrePaid = () => {
    if (loading) {
      return "Loading...";
    } else if (error) {
      return "Failed Loading";
    } else if (!payments) {
      return "N/A";
    } else if (payments && payments.length > 0 && payments[0].totalAmount) {
      return (
        "Rs " + numberWithCommas((payments[0].totalAmount / 100).toFixed(2))
      );
    } else return "N/A";
  };

  const getExpireTime = () => {
    if (loadingSubscribeStatus) {
      return "Loading...";
    } else if (errorSubscribeStatus) {
      return null;
    } else if (
      subscribeStatus &&
      subscribeStatus.hasOwnProperty("statusExpireTime")
    ) {
      if (
        subscribeStatus.statusExpireTime === "" ||
        !subscribeStatus.statusExpireTime
      )
        return null;
      return formatDateTimeByString(subscribeStatus.statusExpireTime);
    } else return null;
  };

  return (
    <div className="payments">
      {isPrepaidAcc ? (
        <div>
          <div className="total-amount-row clearfix mb-1">
            <div className="total-label">Balance :</div>
            <strong className="total-amount">{paymentSecurePrePaid()}</strong>
          </div>
          {getExpireTime() !== null ? (
            <div>
              <div className="expire mb-1">
                Expire on :<strong>{` ${getExpireTime()}`}</strong>
              </div>
            </div>
          ) : null}
          <div className="button-container-row clearfix d-flex justify-content-center">
            <div className="button-container-50 mt-2">
              <button
                disabled={!accountList || accountList.length === 0}
                onClick={() => history.push("/prepaid/transactions")}
              >
                Transaction
              </button>
            </div>
            <div className="button-container-50 mt-2">
              <button
                disabled={!accountList || accountList.length === 0}
                onClick={handleReloadClick}
              >
                Reload
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="total-amount-row clearfix">
            <div className="total-label">Total Payable :</div>
            <strong className="total-amount">{paymentSecure()}</strong>
          </div>
          <div className="button-container-row clearfix">
            <div className="button-container-50">
              <button
                disabled={!accountList || accountList.length === 0}
                onClick={() => history.push("/bill/0")}
              >
                Pay Now
              </button>
            </div>
            <div className="button-container-50">
              <button
                disabled={!accountList || accountList.length === 0}
                onClick={() => history.push("/bill/1")}
              >
                Bill History
              </button>
            </div>
          </div>
        </div>
      )}
      {isError && (
        <PaymentsErrorAlert
          error={errorMessage}
          setError={setErrorMessage}
          setIsError={setIsError}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.accountDetails.loadingPayments,
  error: state.accountDetails.errorPayments,
  payments: state.accountDetails.payments,
  accountList: state.accountDetails.account_Details,
  loadingServices: state.accountDetails.loadingServiceList,
  serviceList: state.accountDetails.service_list,
  loadingSubscribeStatus: state.accountDetails.loadingSubscribeStatus,
  subscribeStatus: state.accountDetails.subscribeStatus,
  errorSubscribeStatus: state.accountDetails.errorSubscribeStatus,
});
Payments.propTypes = {
  payments: PropTypes.object,
};
export default connect(mapStateToProps, {})(Payments);
