import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getPackageUpgradeList,
  packageUpgradeAPI,
} from "../../../redux/action/broadBand/packageUpgrade.action";
import LoadingBbBody from "../LoadingBbBody";
import "react-confirm-alert/src/react-confirm-alert.css";
import ConfirmAlert from "../../alerts/ConfirmAlert";
import SuccessAlert from "../../alerts/SuccessAlert";
import ErrorAlert from "../../alerts/ErrorAlert";
import NoDataToShow from "../../common/NoDataToShow";
import InfiniteCarousel from "react-leaf-carousel";

const PackageUpgrade = ({
  getPackageUpgradeList,
  packageUpgradeList,
  loading,
  serviceList,
  profile,
  currentPackage,
  packageUpgradeAPI,
}) => {
  //   const [type, setType] = useState("Standard");

  const [type, setType] = useState("Any");

  useEffect(() => {
    if (
      !packageUpgradeList?.Upgrades?.Any ||
      !Array.isArray(packageUpgradeList.Upgrades.Any) ||
      packageUpgradeList.Upgrades.Any.length === 0
    ) {
      setType("Unlimited");
    } else {
      setType("Any");
    }
  }, [packageUpgradeList]);

  const [confirmShow, setConfirmShow] = useState(false);
  const [pName, setPName] = useState("");
  const [pPrice, setPPrice] = useState("");
  const [success, setSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    getPackageUpgradeList(
      serviceList.listofBBService[0].serviceType,
      serviceList.listofBBService[0].packageName
    );
  }, [getPackageUpgradeList, serviceList]);

  const selectArray = () => {
    if (!loading && packageUpgradeList) {
      //   if (
      //     packageUpgradeList.hasOwnProperty("Upgrades") &&
      //     type === "Standard"
      //   ) {
      //     return packageUpgradeList.Upgrades.Standard[0];
      //   } else
      if (
        packageUpgradeList.hasOwnProperty("Upgrades") &&
        type === "Any" &&
        packageUpgradeList.Upgrades.Any !== undefined &&
        packageUpgradeList.Upgrades.Any !== null &&
        Array.isArray(packageUpgradeList.Upgrades.Any) &&
        packageUpgradeList.Upgrades.Any.length > 0 &&
        packageUpgradeList.Upgrades.Any[0] !== null
      ) {
        return packageUpgradeList.Upgrades.Any[0];
      } else if (
        packageUpgradeList.hasOwnProperty("Upgrades") &&
        type === "Unlimited" &&
        packageUpgradeList.Upgrades.Unlimited !== undefined &&
        packageUpgradeList.Upgrades.Unlimited !== null &&
        Array.isArray(packageUpgradeList.Upgrades.Unlimited) &&
        packageUpgradeList.Upgrades.Unlimited.length > 0 &&
        packageUpgradeList.Upgrades.Unlimited[0] !== null
      ) {
        if (packageUpgradeList.Upgrades.hasOwnProperty("Unlimited"))
          return packageUpgradeList.Upgrades.Unlimited[0];
        else return [];
      } else {
        return [];
      }
    } else return [];
  };

  const hiddenCondition = (button) => {
    if (!loading && packageUpgradeList) {
      // if (packageUpgradeList.hasOwnProperty('Upgrades') && button === 'Standard') {
      //     return packageUpgradeList.Upgrades.Standard[0].length === 0
      // } else
      if (
        packageUpgradeList.hasOwnProperty("Upgrades") &&
        button === "Any" &&
        packageUpgradeList.Upgrades.Any !== undefined &&
        packageUpgradeList.Upgrades.Any !== null &&
        Array.isArray(packageUpgradeList.Upgrades.Any) &&
        packageUpgradeList.Upgrades.Any.length > 0 &&
        packageUpgradeList.Upgrades.Any[0] !== null
      ) {
        return packageUpgradeList.Upgrades.Any[0].length === 0;
      } else if (
        packageUpgradeList.hasOwnProperty("Upgrades") &&
        button === "Unlimited" &&
        packageUpgradeList.Upgrades.Unlimited !== undefined &&
        packageUpgradeList.Upgrades.Unlimited !== null &&
        Array.isArray(packageUpgradeList.Upgrades.Unlimited) &&
        packageUpgradeList.Upgrades.Unlimited.length > 0 &&
        packageUpgradeList.Upgrades.Unlimited[0] !== null
      ) {
        if (packageUpgradeList.Upgrades.hasOwnProperty("Unlimited"))
          return packageUpgradeList.Upgrades.Unlimited[0].length === 0;
        else return true;
      } else {
        return true;
      }
    } else return true;
  };

  const callAPI = () => {
    let subscriberId = profile.subscriberid;
    let type = serviceList.listofBBService[0].serviceType;
    let name = profile.fullname;
    let nic = "NA";
    let mobile = profile.phone;
    let email = profile.email;
    let exsistingPackage = currentPackage.bB_PACKAGE_NAME;
    let newPackageSelected = pName;
    let currentMonthlyValue = currentPackage.monthlY_RENTAL;
    let MonthlyValue = pPrice;
    let changeType = "Upgrade";

    packageUpgradeAPI(
      subscriberId,
      type,
      name,
      nic,
      mobile,
      email,
      exsistingPackage,
      newPackageSelected,
      currentMonthlyValue,
      MonthlyValue,
      changeType,
      setSuccess,
      setIsError,
      setError
    );
  };

  if (loading) {
    return <LoadingBbBody loading={loading} border={true} />;
  } else if (!packageUpgradeList.hasOwnProperty("Upgrades")) {
    return (
      <div className="getExtraGB common-panel">
        <NoDataToShow />
      </div>
    );
  } else if (packageUpgradeList) {
    return (
      <div className="packageUpgrade common-panel">
        {confirmShow ? (
          <ConfirmAlert
            setConfirmShow={setConfirmShow}
            onClick={callAPI}
            msg={"You want to upgrade package to " + pName + "?"}
          />
        ) : null}

        {success ? (
          <SuccessAlert setSuccess={setSuccess} msg="Successfully requested" />
        ) : null}

        {isError ? (
          <ErrorAlert
            error={error}
            setError={setError}
            setIsError={setIsError}
          />
        ) : null}

        <div className="current-package-container">
          <div>
            <div className="bodyView">
              <div className="current-package">
                <div className="icon-correct"></div>
                <div className="titleCurrent">Current Package</div>
              </div>
              <div className="package-info">
                <div className="packageName">
                  {currentPackage.bB_PACKAGE_NAME}
                </div>
                <div className="priceDetails">
                  Rs.{currentPackage.monthlY_RENTAL} +Tax
                </div>
                <div className="per-month">(Per Month)</div>
              </div>
              <div className="amountDetails">
                <div className="package-box standard">
                  <div className="package-type">Standard</div>
                  <div className="package-amount">
                    {currentPackage.standarD_GB}GB
                  </div>
                </div>
                <div className="plus"></div>
                <div className="package-box free">
                  <div className="package-type">Free</div>
                  <div className="package-amount">
                    {currentPackage.freE_GB}GB
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="new-package-container">
          <div className="topButtons">
            {/* <button
              hidden={hiddenCondition("Standard")}
              className={type === "Standard" ? "blue" : null}
              onClick={() => setType("Standard")}
            >
              VALUE PACKAGE
            </button> */}
            <button
              hidden={hiddenCondition("Any")}
              className={type === "Any" ? "blue" : null}
              onClick={() => setType("Any")}
            >
              ANY TIME
            </button>
            <button
              hidden={hiddenCondition("Unlimited")}
              className={type === "Unlimited" ? "blue" : null}
              onClick={() => setType("Unlimited")}
            >
              UNLIMITED
            </button>
          </div>

          <div className="bodyView">
            <InfiniteCarousel
              key={selectArray().length}
              breakpoints={[
                {
                  breakpoint: 500,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  },
                },
              ]}
              dots={false}
              showSides={true}
              sidesOpacity={0.5}
              sideSize={0.1}
              slidesToScroll={3}
              slidesToShow={3}
              scrollOnDevice={true}
              autoCycle={true}
            >
              {selectArray().map((val, key) => {
                return (
                  <div key={key}>
                    <div className="package-item">
                      <div className="packageName">{val.BB_PACKAGE_NAME}</div>

                      {
                        //   type === "Standard" ? (
                        //     <div className="amountDetails">
                        //       <div className="package-box standard">
                        //         <div className="package-type">Standard</div>
                        //         <div className="package-amount">
                        //           {val.STANDARD_GB}GB
                        //         </div>
                        //       </div>
                        //       <div className="plus"></div>
                        //       <div className="package-box free">
                        //         <div className="package-type">Free</div>
                        //         <div className="package-amount">
                        //           {val.FREE_GB}GB
                        //         </div>
                        //       </div>
                        //     </div>
                        //   ) :
                        type === "Any" ? (
                          <div className="amountDetails">
                            <div className="package-box any">
                              <div className="package-type">
                                {val.STANDARD_GB}GB &nbsp; {val.DESCRIPTION}
                              </div>
                            </div>
                            {/*<div className="plus">*/}
                            {/*</div>*/}
                            {/*<div className="package-box free">*/}
                            {/*    <div className="package-type">Free</div>*/}
                            {/*    <div className="package-amount">{val.FREE_GB}GB</div>*/}
                            {/*</div>*/}
                          </div>
                        ) : (
                          <div className="amountDetails">
                            <div className="package-box any">
                              {/*<div className="package-type">Any</div>*/}
                              <div className="package-type">
                                {val.DESCRIPTION}GB
                              </div>
                            </div>
                            {/*<div className="plus">*/}
                            {/*</div>*/}
                            {/*<div className="package-box free">*/}
                            {/*    <div className="package-type">Free</div>*/}
                            {/*    <div className="package-amount">{val.FREE_GB}GB</div>*/}
                            {/*</div>*/}
                          </div>
                        )
                      }

                      <div className="priceDetails">
                        <div className="price">
                          {" "}
                          Rs.{val.MONTHLY_RENTAL} + Tax{" "}
                        </div>
                        <div className="per-month">(Per Month)</div>
                      </div>
                      <div className="btn-container">
                        <button
                          className="btn-upgrade"
                          onClick={() => {
                            setPName(val.BB_PACKAGE_NAME);
                            setPPrice(val.MONTHLY_RENTAL);
                            setConfirmShow(true);
                          }}
                        >
                          Upgrade
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteCarousel>
          </div>
        </div>
      </div>
    );
  } else return <h3>null</h3>;
};

const mapStateToProps = (state) => ({
  loadingProfile: state.broadBand.loadingProfile,
  profile: state.broadBand.profile,
  serviceList: state.accountDetails.service_list,
  packageUpgradeList: state.packageUpgrade.dataBundle,
  currentPackage: state.packageUpgrade.currentPackage,
  loading: state.packageUpgrade.loading,
  error: state.packageUpgrade.error,
});

export default connect(mapStateToProps, {
  getPackageUpgradeList,
  packageUpgradeAPI,
})(PackageUpgrade);
