import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import LoadingBbBody from "../../boardBand/LoadingBbBody";
import ErrorAlert from "../../alerts/ErrorAlert";
import { isPrepaid } from "../../../redux/action/accountDetails.action";
import {
  getPrepaidReloadHashCodeUrl,
  getViewUserInfoUrl,
} from "../../../redux/URL";
import axios from "axios";
import PayHere from "./PayHere";
import axiosInstance from "../../../redux/axiosInstance";
import { CATCH_ERROR_MSG } from "../../../redux/Types";

function PrepaidReload({
  loadingServices,
  loadingAccounts,
  loadingProfile,
  serviceList,
}) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [mobile, setMobile] = useState(localStorage.getItem("selectedAcc"));
  const [amount, setAmount] = useState(0);
  const [amountError, setAmountError] = useState("");

  const [uname, setUname] = useState("");
  const [altContact, setAltContact] = useState("");

  const telephoneNumber = localStorage.getItem("selectedAcc");
  const userType = localStorage.getItem("userType");
  const userLoggedName = localStorage.getItem("userName");

  const getUserDetails = () => {
    setLoading(true);
    axiosInstance
      .get(getViewUserInfoUrl)
      .then((response) => {
        if (response.data.isSuccess) {
          setLoading(false);
          setUname(response.data.dataBundle.name);
          setAltContact(response.data.dataBundle.altrContact);
        } else {
          setError(response.data.erroShow);
          setIsError(true);
          setLoading(false);
        }
      })
      .catch(() => {
        setError(CATCH_ERROR_MSG);
        setIsError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const onSuccess = () => {
    history.push("/prepaid/transactions");
  };

  const { initiatePayment } = PayHere({ setLoading, onSuccess });

  if (!isPrepaid(serviceList && serviceList))
    return <Redirect to="/boardBand/summary" />;

  if (loadingAccounts || loadingServices || loadingProfile || loading) {
    return (
      <div className="mainBody eBill common-panel">
        <LoadingBbBody loading={true} border={false} />
      </div>
    );
  }

  const getHashCode = async () => {
    setLoading(true);
    try {
      let body = new URLSearchParams({
        Amount: amount,
        ContactNo: userType === "Email" ? userLoggedName : altContact,
        Email: userType === "Email" ? altContact : userLoggedName,
        Status: "Pending",
        APIKey: "LTE_WEB",
        customerName: uname,
        TelephoneNo: telephoneNumber,
      });
      const result = await axios.post(getPrepaidReloadHashCodeUrl, body, {
        headers: {
          "X-IBM-Client-Id": "34cfc6db495c327709aa0ad47cd3fbd6",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (result.data.isSuccess) {
        const payment = {
          sandbox: false,
          merchant_id: "212500",
          return_url: undefined,
          cancel_url: undefined,
          notify_url:
            "https://lteprepaidbackend.slt.lk/PayhereAPI/Home/PayHereNotifyPage",
          order_id: result.data.ReferenceNo,
          items: "MySLT Bill",
          amount: amount,  //amount
          hash: result.data.Hash,
          currency: "LKR",
          first_name: uname,
          last_name: "",
          email: "",
          phone: telephoneNumber,
          address: "",
          city: "",
          country: "",
          custom_1: telephoneNumber,
        };

        initiatePayment(payment);
        console.log(payment);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mainBody bill common-panel">
      {isError ? (
        <ErrorAlert error={error} setError={setError} setIsError={setIsError} />
      ) : null}
      <div className="body-container">
        <div className="title">Reload</div>
        <div className="wrapper">
          <div>
            <label className="d-block">
              Prepaid Number<p className="d-inline red">*</p>
            </label>
            <input
              type="text"
              className="pLeft"
              value={mobile}
              disabled
              onChange={(e) => {
                setMobile(e.target.value.trim());
              }}
              required
            />
          </div>

          <div className="mt-3">
            <label className="d-block">
              Amount (LKR) <p className="d-inline red">*</p>
            </label>
            <input
              type="number"
              className="pLeft"
              value={amount}
              onChange={(e) => {
                const value = parseFloat(e.target.value.trim());
                if (value < 100) {
                  setAmountError(
                    "Payment amount must be greater than or equal to Rs. 100."
                  );
                } else {
                  setAmountError("");
                }
                setAmount(value);
              }}
              required
            />
            {amountError && (
              <p className="error-text" style={{ color: "red" }}>
                {amountError}
              </p>
            )}
          </div>

          <div className="submit mt-3 ">
            <button
              className="medium blue"
              disabled={!amount || !mobile || amount < 100}
              onClick={() => getHashCode()}
            >
              Pay Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loadingAccounts: state.accountDetails.loading,
  loadingServices: state.accountDetails.loadingServiceList,
  loadingProfile: state.broadBand.loadingProfile,
  payments: state.accountDetails.payments,
  serviceList: state.accountDetails.service_list,
  profile: state.broadBand.profile,
});

export default connect(mapStateToProps, {})(PrepaidReload);
