import React, { useEffect, useState } from 'react';
import { BsFillCaretRightFill } from "react-icons/bs";
import { connect } from "react-redux";
import { getDataAddOnsList, addAddOnsData } from "../../../redux/action/broadBand/dataAddOns.action";
import LoadingBbBody from "../LoadingBbBody";
import SuccessAlert from "../../alerts/SuccessAlert";
import ErrorAlert from "../../alerts/ErrorAlert";
import ConfirmAlert from "../../alerts/ConfirmAlert";
import ConfirmRecurrentAlert from '../../alerts/ConfirmRecurrentAlert';
import NoPreviledge from "../../alerts/NoPreviledge";
import { trim } from 'jquery';
// import { useParams } from 'react-router-dom';


const GetDataAddOns = ({ profile, loading, addOnsList, getDataAddOnsList, addAddOnsData }) => {

    let { mode } = 0//useParamss();
    const [isRecurrent, setIsRecurrent] = React.useState(Boolean(Number(mode)));

    const [type, setType] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    const [confirmShow, setConfirmShow] = useState(false);
    const [pName, setPName] = useState('');
    const [pId, setPId] = useState('');
    const [rId, setRId] = useState('');
    const [pFId, setPFId] = useState('');
    const [success, setSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (profile.priviledges.data_addons) {
            setType(null)
            setCategoryList([])
            getDataAddOnsList(profile.subscriberid, profile.subscriber_package)
        }
    }, [getDataAddOnsList, profile]);

    useEffect(() => {
        if (addOnsList.hasOwnProperty('categories')) {
            let catList = addOnsList.categories.map((item => trim(item['category'])))
            
            if (catList.includes('Independence Day Free Offer')) {
                setType(trim('Independence Day Free Offer'))
                catList.unshift(catList.pop());
                setCategoryList(catList)
            } else {
                setType(catList[0])
                setCategoryList(catList)
            }
        }

    }, [addOnsList]);

    useEffect(() => {
        if (isRecurrent) {
            setPFId(rId)
        } else {
            setPFId(pId)
        }
    }, [isRecurrent, pId, setPFId, rId])

    const selectArray = (categoryType) => {
        if (!loading && addOnsList) {
            if (typeof addOnsList.categories === undefined)
                return []
            if (!addOnsList.categories.hasOwnProperty('length'))
                return []
            for (let i = 0; i < addOnsList.categories.length; i++) {

                if (trim(addOnsList.categories[i].category) === categoryType) {

                    let addOnsLists = []

                    for (let k = 0; k < addOnsList.categories[i].addons.length; k++) {
                        if (addOnsList.categories[i].addons[k].name.includes("Recurrent")) {

                        } else if (addOnsList.categories[i].addons[k].name.includes("One Time")) {
                            for (let j = 0; j < addOnsList.categories[i].addons.length; j++) {
                                if (addOnsList.categories[i].addons[j].name.includes("Recurrent")) {
                                    let text = addOnsList.categories[i].addons[j].name;
                                    let separated = text.split("Recurrent");
                                    let addOnName = (separated[0]);

                                    if (addOnsList.categories[i].addons[k].name.includes(addOnName)) {
                                        addOnsList.categories[i].addons[k].name = addOnName
                                        addOnsList.categories[i].addons[k].id_recurrent = addOnsList.categories[i].addons[j].id
                                    }
                                }

                            }
                            addOnsLists.push(addOnsList.categories[i].addons[k])
                        } else {
                            addOnsLists.push(addOnsList.categories[i].addons[k])
                        }
                    }
                    
                    return addOnsLists //addOnsList.categories[i].addons
                }
            }
        } else
            return []
        return []
    }

    const dataAddOns = () => {
        // addAddOnsData(profile.subscriberid, pId, localStorage.getItem('userName'), 'SCP', setSuccess, setIsError, setError)
        addAddOnsData(profile.subscriberid, pFId, localStorage.getItem('userName'), 'SCP', setSuccess, setIsError, setError)
    }

   

    if (!profile.priviledges.data_addons) {
        return <div className='dataAddOns noPadding'>
            <NoPreviledge msg='Please change the SLT package for purchase Add-on Data' />
        </div>
    } else if (loading) {
        return <LoadingBbBody loading={loading} border={false} />
    } else if (addOnsList.hasOwnProperty('categories')) {
        return (
            <div className='dataAddOns common-panel'>
                {/* type === 'Home Schooling & WFH' || type === 'LMS' ? 'Do you want to purchase and activate one time ' + pName + '?' : 'Do you want to purchase and activate auto renew ' + pName + '?' */}
                {confirmShow ? <ConfirmRecurrentAlert setConfirmShow={setConfirmShow} onClick={dataAddOns} msg={
                    type === 'Entertainment Unlimited' || type === 'Gaming' || type === 'YouTube' || type === 'Messaging' ? 'Do you want to purchase and activate auto renewing ' + pName + ' Add-on'+ '?' : type === 'Seasonal Offer' ? 'Do you want to redeem free data bundle?' : 'Do you want to purchase and activate ' + pName + '?'
                } pId={pId} rId={rId} setIsRecurrent={setIsRecurrent} isRecurrent={isRecurrent} /> : null}

                {success ? <SuccessAlert setSuccess={setSuccess} msg='You have purchased the data Add-on successfully' /> : null}

                {isError ? <ErrorAlert error={error} setError={setError} setIsError={setIsError} /> : null}

                <div className='addon-container'>
                    <div className='leftSide'>

                        {
                            categoryList.map((value, index) => {
                                return <div className="buttonTab-wrapper" hidden={selectArray(value).length === 0} index={index}>
                                    <div className={type === value ? 'buttonTab blue' : 'buttonTab'}
                                        onClick={() => setType(value)}>
                                        <div className='text-center'>
                                            <p className='m-0'>{value}</p>
                                        </div>
                                        <span hidden={type !== value}>
                                            <BsFillCaretRightFill size={20} color='#007ed6' />
                                        </span>
                                    </div>
                                </div>
                            })
                        }

                    </div>

                    <div className='rightSide'>
                        <div className='addon-items clearfix'>

                            {
                                selectArray(type).map((val, index) => {
                                    return (
                                        <div className="item-container" key={index} onClick={() => {
                                            setPName(val.name)
                                            setPId(val.id)
                                            setPFId(val.id)
                                            setRId(val.id_recurrent ? val.id_recurrent : '')
                                            setConfirmShow(true)
                                        }}>
                                            <div className="item">
                                                <div className='title'> {val.name}</div>
                                                <div className="banner-container" style={{ backgroundImage: `url(${val.icon_url})` }} >
                                                    {/* <img src={val.icon_url} alt='banner' className='imageView'/> */}
                                                    <div className='priceTag'>
                                                        <div>{val.description}</div>
                                                        {
                                                            type === 'Seasonal Hamper' ? <div>Free</div> : <div>Rs. {val.postprice}</div>
                                                        }

                                                    </div>
                                                </div></div></div>
                                    )
                                })
                            }


                        </div>
                    </div>
                </div>
            </div>
        );
    } else
        return null
}

const mapStateToProps = state => ({
    loadingProfile: state.broadBand.loadingProfile,
    profile: state.broadBand.profile,
    addOnsList: state.dataAddOns.dataBundle,
    loading: state.dataAddOns.loading,
    error: state.dataAddOns.error,
})

export default connect(mapStateToProps, { getDataAddOnsList, addAddOnsData })(GetDataAddOns);

