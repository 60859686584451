import './assests/css/main.scss'
import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import store from './redux/store'
import PublicRoute from "./navigation/PublicRoute";
import SignIn from "./component/publicViews/SignIn";
import Register from "./component/publicViews/Register";
import OtpValidation from "./component/publicViews/OtpValidation";
import ForgotPassword from "./component/publicViews/ForgotPassword";
import RegisterOtp from "./component/publicViews/RegisterOtp";
import PrivateRoute from "./navigation/PrivateRoute";
import NoAnyAccount from "./component/boardBand/NoAnyAccount";
import PeoTvMain from "./component/peoTV/PeoTVMain";
import InitialBroadBand from "./component/boardBand/InitialBroadBand";
import Bill from "./component/bill/Bill";
import ComplaintsList from './component/complaint/ComplaintsList';
import UpdateEBillSms from "./component/bill/UpdateEBillSms";
import ManageConnections from "./component/MyProfile/ManageConnections";
import FAQ from './component/MyProfile/FAQ';
import VoiceMain from "./component/voice/VoiceMain";
import MyProfile from "./component/MyProfile/MyProfile";
import MainFTTH from "./component/ftth/MainFTTH";
import CreateComplaint from './component/complaint/CreateComplaint';
import NewServices from "./component/newServices/NewServices";
import DigitalLife from "./component/digitalLife/DigitalLife";
import PaySuccess from "./component/ftth/PaySuccess";
import PaySuccessLte from './component/lte/PaySuccessLte';
// import FileUploadsFTTH from "./component/ftth/FileUploadsFTTH";
import ErrorShow from "./component/ErrorShow";
import Mobile from "./component/mobile/Mobile";
import LandingPage from "./component/NewLanding/LandingPage";
import TagManager from 'react-gtm-module'

import MainLTE from './component/lte/MainLTE';
import Cart from './component/cart/Cart';
import PrepaidMain from './component/prepaid/PrepaidMain';
import TransactionHistory from './component/prepaid/TransactionsHistory';
import promo from './component/promo';
import LandingFTTHPage from './component/NewFTTHLanding/LandingFTTHPage';
import FileUploadsFTTH from './component/NewFTTHLanding/ftth/FileUploadsFTTH';
import MobileStateCheckFTTH from './component/NewFTTHLanding/ftth/MobileStateCheckFTTH';
import Reload from './component/prepaid/Reload';


const tagManagerArgs = {
    gtmId: 'GTM-TJ26ZH3'
}
 
TagManager.initialize(tagManagerArgs)

function App() {
    
    return (
        <Provider store={store}>

            <Router>

                <PublicRoute path='/' component={SignIn} exact />
                <PublicRoute path='/register' component={Register} exact />
                <PublicRoute path='/forgotPassword' component={ForgotPassword} exact />
                <PublicRoute path='/otpValidation' component={OtpValidation} exact />
                <PublicRoute path='/registerOtp/:id' component={RegisterOtp} exact />
                <PublicRoute path='/promo/buyonline' component={LandingPage} type='any' exact/>
                <PublicRoute path='/promo/buyonline/:path' component={LandingPage} type='any' exact/>

                <PublicRoute path='/applyonline' component={LandingFTTHPage} type='FTTHNEW'exact />
                <PublicRoute path='/applyonline/:path' component={LandingFTTHPage} type='FTTHNEW'exact />
                <PublicRoute path='/applyonline/fibre/statustracking/:orderId' component={FileUploadsFTTH} type='FTTHNEW'/>
                <PublicRoute path='/fttx/:orderId/:status' component={FileUploadsFTTH} type='FTTHNEW'/>
                <PublicRoute path='/applyonline/status/:orderId' component={MobileStateCheckFTTH} type='FTTHNEW'/>

                <PrivateRoute path='/boardBand' component={InitialBroadBand} />
                <PrivateRoute path='/peoTV' component={PeoTvMain} />
                <PrivateRoute path='/voice' component={VoiceMain} />
                <PrivateRoute path='/mobile' component={Mobile} />
                <PrivateRoute path='/promotion' component={promo} />
                <PrivateRoute path='/prepaid/boardBand' component={PrepaidMain} />
                <PrivateRoute path='/prepaid/transactions' component={TransactionHistory} />
                <PrivateRoute path='/prepaid/reload' component={Reload} />

                <PrivateRoute path='/newService/:status/:type' component={NewServices} type='FTTH'/>
                <PrivateRoute path='/bill/:type' component={Bill} />
                <PrivateRoute path ='/eBill' component={UpdateEBillSms} />
                <PrivateRoute path ='/complaints' component={ComplaintsList} />
                <PrivateRoute path ='/add_complaint' component={CreateComplaint} />
                <PrivateRoute path ='/digitalLife' component={DigitalLife} />
                <PrivateRoute path ='/manageConnections' component={ManageConnections} />
                <PrivateRoute path ='/faq' component={FAQ} />
                <PrivateRoute path ='/myProfile' component={MyProfile} />

                {/* <PrivateRoute path='/ftthrequest' component={MainFTTH} type='FTTH'/> */}
                {/* <PrivateRoute path='/connectionOrder' component={FileUploadsFTTH} type='FTTH'/> */}

                {/* <PrivateRoute path='/lterequest' component={MainLTE} type='LTE'/> */}
                <PrivateRoute path='/LTE/:orderId/:status' component={PaySuccessLte} type='LTE'/> 
                {/* <PrivateRoute path='/cart' component={Cart} type='LTE'/> */}

                <PrivateRoute path='/noAccount' component={NoAnyAccount} />
                <PrivateRoute path='/errorShow' component={ErrorShow} />

            </Router>


        </Provider>
    );
}

export default App;
