import React, { useState } from "react";
import { connect } from "react-redux";
import BroadBandSummary from "./BroadBandSummary";
import GiftData from "./giftData/GiftData";
import {
  Link,
  Route,
  Switch,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import DailyUsageOneDay from "./dailyUsage/DailyUsageOneDay";
import SubscribeDetailedReport from "./dailyUsage/SubscribeDetailedReport";
import ChangeBroadbandPassword from "./changeBroadbandPassword/ChangeBroadbandPassword";
import ChangeContactInformation from "./changeContactInformation/ChangeContactInformation";
import BroadBandPurhaseHistory from "./broadBandPurchaseHistory/BroadBandPurhaseHistory";
import RedeemVoucher from "./redeemVoucher/RedeemVoucher";
import HappyDay from "./happyDay/HappyDay";
import useComponentVisible from "../common/UseComponentVisible";
import DailyDetails from "./dailyUsage/DailyDetails";

function BroadBandNav({ profile }) {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const [state, setState] = useState("summary");
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  function classNameSet(link) {
    let word = window.location.href;

    if (word.split("/").includes(link)) {
      return "buttonBB colored";
    } else {
      return "buttonBB";
    }
  }

  function dailyUsageClass() {
    let word = window.location.href;

    if (word.split("/").includes("protocolReport")) {
      return "buttonBB colored";
    } else if (word.split("/").includes("dailyUsage")) {
      return "buttonBB colored";
    } else {
      return "buttonBB";
    }
  }

  function summaryClassName() {
    let word = window.location.href;

    if (word.split("/").includes("summary") && state === "summary") {
      return "buttonBB colored";
    } else {
      return "buttonBB";
    }
  }

  function optionClassNameSet(link) {
    let word = window.location.href;

    if (word.split("/").includes(link)) {
      return "colored";
    } else {
      return "";
    }
  }

  // function redirect(e) {
  //     if (e.target.value === 'protocol')
  //         history.push('/boardBand/enableProtocol')
  //     else if (e.target.value === 'bbPassword')
  //         history.push('/boardBand/changeBroadbandPassword')
  //     else if (e.target.value === 'contact')
  //         history.push('/boardBand/changeContactInformation')
  // }

  return (
    <div className="mainBody">
      <div className="boardBandNav">
        <div className="buttonBB-wrapper">
          <Link
            to={`${url}/summary`}
            className={summaryClassName()}
            onClick={() => setState("summary")}
          >
            <div className="white">Summary</div>
          </Link>
        </div>
        <div className="buttonBB-wrapper">
          <Link to={`${url}/dailyUsage`} className={dailyUsageClass()}>
            <div>Daily Usage</div>
          </Link>
        </div>
        <div className="buttonBB-wrapper">
          <Link to={`${url}/giftData`} className={classNameSet("giftData")}>
            <div>Gift Data</div>
          </Link>
        </div>
        <div className="buttonBB-wrapper">
          <Link to="/boardBand/history" className={classNameSet("history")}>
            <div>History</div>
          </Link>
        </div>
        <div className="buttonBB-wrapper">
          <Link
            to="/boardBand/redeemVoucher"
            className={classNameSet("redeemVoucher")}
          >
            <div>Redeem Data</div>
          </Link>
        </div>
        <div className="buttonBB-wrapper">
          <Link to="/boardBand/happyDay" className={classNameSet("happyDay")}>
            <div>Happy Day</div>
          </Link>
        </div>
        <div className="buttonBB-wrapper">
          {/*<select value='More' onChange={(e) => redirect(e)}>*/}
          {/*    <option value='protocol'*/}
          {/*            className={optionClassNameSet('enableProtocol')}>{profile.hasOwnProperty('priviledges') ? profile.priviledges.protocol_report ? 'Disable' : 'Enable' : null} Detail*/}
          {/*        Report*/}
          {/*    </option>*/}
          {/*    <option value='contact' className={optionClassNameSet('changeContactInformation')}>Change*/}
          {/*        Contact*/}
          {/*        Information*/}
          {/*    </option>*/}
          {/*    <option value='bbPassword' className={optionClassNameSet('changeBroadbandPassword')}>Change*/}
          {/*        Broadband Password*/}
          {/*    </option>*/}
          {/*    <option value='More' hidden={true}>More</option>*/}
          {/*</select>*/}

          <div className="buttonBB">
            <div onClick={() => setIsComponentVisible(!isComponentVisible)}>
              <div>More</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="7"
                fill="none"
                viewBox="0 0 16 10"
              >
                <path
                  fill="#fff"
                  d="M7.779 9.428L0 2.357 2.594 0 7.78 4.715 12.963 0l2.595 2.357-7.78 7.071z"
                />
              </svg>
            </div>
            <div className="moreBb" ref={ref} hidden={!isComponentVisible}>
              <div
                className={optionClassNameSet("enableProtocol")}
                onClick={() => {
                  history.push("/boardBand/enableProtocol");
                  setIsComponentVisible(false);
                }}
              >
                {profile.hasOwnProperty("priviledges")
                  ? profile.priviledges.protocol_report
                    ? "Disable"
                    : "Enable"
                  : null}{" "}
                Detail Report
              </div>

              <div
                className={optionClassNameSet("changeContactInformation")}
                onClick={() => {
                  history.push("/boardBand/changeContactInformation");
                  setIsComponentVisible(false);
                }}
              >
                Change Contact Information
              </div>

              {/* <div className={optionClassNameSet('changeBroadbandPassword')} onClick={() => {
                                history.push('/boardBand/changeBroadbandPassword')
                                setIsComponentVisible(false)
                            }}>Change Broadband Password
                            </div> */}
            </div>
          </div>
        </div>
      </div>

      <Switch>
        <Route path={`${path}/summary`}>
          <BroadBandSummary state={state} setState={setState} />
        </Route>
        <Route path={`${path}/dailyUsage`}>
          <DailyDetails />
        </Route>
        <Route path={`${path}/protocolReport/:date`}>
          <DailyUsageOneDay />
        </Route>
        <Route path={`${path}/giftData`}>
          <GiftData />
        </Route>
        <Route path={`${path}/enableProtocol`}>
          <SubscribeDetailedReport />
        </Route>
        <Route path={`${path}/changeBroadbandPassword`}>
          <ChangeBroadbandPassword />
        </Route>

        <Route path={`${path}/changeContactInformation`}>
          <ChangeContactInformation />
        </Route>
        <Route path={`${path}/history`}>
          <BroadBandPurhaseHistory />
        </Route>
        <Route path={`${path}/redeemVoucher`}>
          <RedeemVoucher />
        </Route>
        <Route path={`${path}/happyDay`}>
          <HappyDay />
        </Route>
      </Switch>
    </div>
  );
}

const mapStateToProps = (state) => ({
  profile: state.broadBand.profile,
});
export default connect(mapStateToProps, {})(BroadBandNav);
