import React from "react";
import errorLog from "../../assests/images/error.png";

function PaymentsErrorAlert({ error, setError, setIsError, onclick }) {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000, // Ensure it's on top
      }}
    >
      <div
        style={{
          background: "white",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          width: "90%",
          maxWidth: "400px",
          textAlign: "center",
        }}
      >
        <div
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Error
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <img
              src={errorLog}
              alt="errorIcon"
              style={{ width: "50px", height: "50px" }}
            />
          </div>
          <div style={{ fontSize: "1rem", color: "#333" }}>{error}</div>
        </div>
        <div>
          {setError && (
            <button
              style={{
                backgroundColor: "#007bff",
                color: "white",
                border: "none",
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                setError("");
                setIsError(false);
                if (onclick !== undefined) {
                  onclick();
                }
              }}
            >
              Ok
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default PaymentsErrorAlert;
