import {
    ERROR_GET_ACCOUNT_DETAILS, ERROR_GET_SERVICE_LIST, ERROR_PAYMENT_LIST,
    LOADING_ACCOUNT_DETAILS, LOADING_ADD_ACCOUNT, LOADING_GET_SERVICE_LIST, LOADING_PAYMENT_LIST, SUCCESS_ADD_ACCOUNT,
    SUCCESS_GET_ACCOUNT_DETAILS, SUCCESS_GET_SERVICE_LIST, SUCCESS_PAYMENT_LIST, SUCCESS_SUSCRIBE_STATUS, LOADING_SUSCRIBE_STATUS, ERROR_SUSCRIBE_STATUS

} from "../Types";

const initialState = {
    loading: true,
    account_Details: [],
    error:'',

    service_list: {},
    loadingServiceList: false,
    errorServiceList: '',

    loadingPayments: false,
    payments: [],
    errorPayments: '',

    loadingAddAccount: false,

    loadingSubscribeStatus: true,
    subscribeStatus: {},
    errorSubscribeStatus: ''

}

export default function accountDetailsReducers(state= initialState, action){
    switch (action.type){
        case LOADING_ACCOUNT_DETAILS : {
            return {
                ...state,
                loading: true,
            }
        }

        case SUCCESS_GET_ACCOUNT_DETAILS : {

            return {
                ...state,
                loading: false,
                error: '',
                account_Details: action.payload
            }
        }

        case ERROR_GET_ACCOUNT_DETAILS : {
            return {
                ...state,
                loading: false,
                error: action.payload,
                account_Details: [],
                service_list: []
            }
        }

        case LOADING_GET_SERVICE_LIST: {
            return {
                ...state,
                loadingServiceList: true,
                service_list: {},
                errorServiceList: ''
            }
        }

        case SUCCESS_GET_SERVICE_LIST : {
            return {
                ...state,
                loadingServiceList: false,
                service_list: action.payload
            }
        }

        case ERROR_GET_SERVICE_LIST : {
            return {
                ...state,
                loadingServiceList: false,
                service_list: {},
                errorServiceList: action.payload
            }
        }

        case LOADING_PAYMENT_LIST : {
            return {
                ...state,
                loadingPayments: true,
                payments: {}
            }
        }

        case SUCCESS_PAYMENT_LIST : {
            return {
                ...state,
                loadingPayments: false,
                payments: action.payload
            }
        }

        case ERROR_PAYMENT_LIST : {
            return {
                ...state,
                errorPayments: action.payload,
                loadingPayments: false,
            }
        }

        case LOADING_SUSCRIBE_STATUS : {
            return {
                ...state,
                loadingSubscribeStatus: true,
                subscribeStatus: {}
            }
        }

        case SUCCESS_SUSCRIBE_STATUS : {
            return {
                ...state,
                loadingSubscribeStatus: false,
                subscribeStatus: action.payload
            }
        }

        case ERROR_SUSCRIBE_STATUS : {
            return {
                ...state,
                errorSubscribeStatus: action.payload,
                loadingSubscribeStatus: false,
            }
        }

        case LOADING_ADD_ACCOUNT : {
            return {
                ...state,
                loadingAddAccount: true
            }
        }
        case SUCCESS_ADD_ACCOUNT : {
            return {
                ...state,
                loadingAddAccount: false
            }
        }

        default :
            return state
    }
}
