import { getPrepaidTransactionHistoryUrl } from "../../../redux/URL";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../redux/axiosInstance";
import { CATCH_ERROR_MSG } from "../../../redux/Types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import LoadingBbBody from "../../boardBand/LoadingBbBody";
import ErrorAlert from "../../alerts/ErrorAlert";
import { numberWithCommas } from "../../../redux/action/bill/billStatus.action";
import {
  formatDate,
  formatDateTimeByString,
  formatTimeByString,
} from "../../../utils/utils";
import { isPrepaid } from "../../../redux/action/accountDetails.action";

function TransactionHistory({
  loadingServices,
  loadingAccounts,
  loadingProfile,
  serviceList,
}) {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [history, setHistory] = useState([]);

  // Get today's date
  var today = new Date();

  // Get the date one month ago
  var oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  // Format the dates in YYYYMMDD format
  var todayFormatted = formatDate(today);
  var oneMonthAgoFormatted = formatDate(oneMonthAgo);

  useEffect(() => {
    getTrasactionHistory();
  }, []);

  if (!isPrepaid(serviceList && serviceList))
    return <Redirect to="/boardBand/summary" />;

  function getTrasactionHistory() {
    setLoading(true);
    let url =
      getPrepaidTransactionHistoryUrl +
      "LTESLTNumber=" +
      localStorage.getItem("selectedAcc") +
      "&fromDate=" +
      oneMonthAgoFormatted +
      "&toDate=" +
      todayFormatted;
    axiosInstance
      .get(url)
      .then((response) => {
        if (response.data.isSuccess) {
          setHistory(...[response.data.dataBundle]);
          setLoading(false);
        } else {
          setError(CATCH_ERROR_MSG);
          setIsError(true);
          setLoading(false);
        }
      })
      .catch(() => {
        setError(CATCH_ERROR_MSG);
        setIsError(true);
        setLoading(false);
      });
  }

  if (loadingAccounts || loadingServices || loadingProfile || loading) {
    return (
      <div className="mainBody eBill common-panel">
        <LoadingBbBody loading={true} border={false} />
      </div>
    );
  }

  return (
    <div className="mainBody bill common-panel">
      {isError ? (
        <ErrorAlert error={error} setError={setError} setIsError={setIsError} />
      ) : null}
      <div className="body-container">
        <div className="title">Transactions History</div>
        <div className="wrapper">
          <div className="history">
            <div className="details singleLine">
              <div className="firstRow ">Date</div>
              <div className="firstRow ">Status</div>
              <div className="firstRow ">Reload Amount</div>
            </div>
            {history &&
              history
                .sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
                .map((value, index) => {
                  return (
                    <div className="details singleLine" key={index}>
                      <div>
                        <div className="secondRow">
                          {formatDateTimeByString(value.txnTime)}
                        </div>
                        <div className="secondRow">
                          {formatTimeByString(value.txnTime)}
                        </div>
                      </div>

                      <div className="firstRow">
                        {value.statusCode === "1001"
                          ? "Recharge Successful"
                          : "Recharge Failed"}
                      </div>

                      <div className="firstRow">
                        Rs {numberWithCommas(value.txnAmount)}
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loadingAccounts: state.accountDetails.loading,
  loadingServices: state.accountDetails.loadingServiceList,
  loadingProfile: state.broadBand.loadingProfile,
  payments: state.accountDetails.payments,
  serviceList: state.accountDetails.service_list,
});

export default connect(mapStateToProps, {})(TransactionHistory);
